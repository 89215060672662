<template>
    <div class="cloud-import-edit-setting" v-if="type !== ''">
        <div class="cloud-import-edit-setting__detail">
            <h2 v-if="type === 'FILE'">Файл {{ name }}</h2>
            <h2 v-else-if="type === 'FOLDER'">Папка {{ name }}</h2>
            <p v-if="type === 'FILE'"><strong>Путь:</strong> {{ path }}</p>
            <p v-if="type === 'FOLDER'"><strong>Файл:</strong> {{ lastFileName }}</p>
        </div>

        <div class="cloud-import-edit-setting__container container-block">
            <h2>Листы</h2>
            <div class="container-flex">
                <BaseButton
                    v-for="(status, index) in sheetStatuses"
                    :key="index"
                    :view="getSheetButtonView(status, index)"
                    :disabled="index === sheetIndex"
                    form="square"
                    @click="onEditSettings(index)"
                >
                    {{index + 1}}
                </BaseButton>
            </div>

            <div class="container-flex">
                <BaseButton
                    v-if="lastFileId"
                    view="secondary"
                    form="square"
                    @click="onPreview"
                >
                    Посмотреть
                </BaseButton>
                <BaseButton
                    view="secondary"
                    form="square"
                    style="margin-left: 10px"
                    @click="onCopy"
                >
                    Копировать
                </BaseButton>
                <BaseButton
                    view="third"
                    form="square"
                    style="margin-left: 10px"
                    @click="onLogs"
                >
                    История
                </BaseButton>
                <BaseButton
                    v-if="uploadedAt"
                    view="third"
                    form="square"
                    style="margin-left: 10px"
                    @click="onClearDate"
                >
                    Сбросить (Загружен {{uploadedAt}})
                </BaseButton>
            </div>
        </div>

        <div v-if="lastFileId" class="cloud-import-edit-setting__container container-block">

            <div class="container-block">
                <h2>Статус шаблона</h2>
                <BaseCheckboxV3
                    :class="['cell cell-checkbox']"
                    :size="24"
                    :checked="!!isItOn"
                    @change="onChangeCheckboxValue($event, 'isItOn')"
                />
            </div>
        </div>

        <div v-if="lastFileId" class="cloud-import-edit-setting__container flex-flex">
            <div class="container-block">
                <h2>Настройки шаблона</h2>
            </div>

            <div class="container-flex">
                <div class="container-block">
                    <h3>№ строки с заголовками в файле</h3>
                    <BaseInput
                        class="number-button"
                        :value="headerRowNumber"
                        :theme="'white'"
                        type="number"
                        @onInput="onInputHeaderRowNumber"
                    />
                </div>

                <div class="container-block">
                    <h3>№ строки с данными в файле</h3>
                    <BaseInput
                        class="number-button"
                        :value="dataRowNumber"
                        :theme="'white'"
                        type="number"
                        @onInput="onInputDataRowNumber"
                    />
                </div>
            </div>

            <div class="container-flex">
                <div class="container-block">
                    <h3>Оператор</h3>
                    <BaseSelect
                        :options="supplierList"
                        :value="supplierSelectedId"
                        :canSearch="true"
                        :isVirtualSearch="false"
                        :position="'left'"
                        :style="{width: '250px'}"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectSupplier"
                        @lazyload="onLazyloadSupplier"
                        @onSearchValue="onSearchSupplier"
                        @onToggleDropdown="onOpenSupplierList"
                    />
                </div>

                <div class="container-block">
                    <h3>Город</h3>
                    <BaseSelect
                        :options="cityList"
                        :value="citySelectedId"
                        :canSearch="true"
                        :isVirtualSearch="false"
                        :position="'left'"
                        :style="{width: '250px'}"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectCity"
                        @lazyload="onLazyloadCity"
                        @onSearchValue="onSearchCity"
                        @onToggleDropdown="onOpenCityList"
                    />
                </div>

                <div class="container-block">
                    <h3>Год</h3>
                    <BaseSelect
                        :options="yearList"
                        :value="yearSelected"
                        :style="{width: '250px'}"
                        :position="'left'"
                        :canSearch="true"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectYear"
                    />
                </div>

                <div class="container-block">
                    <h3>Минимальный период размещения</h3>
                    <BaseSelect
                        :options="periodList"
                        :value="periodSelected"
                        :style="{width: '250px'}"
                        :position="'left'"
                        :canSearch="true"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectPeriod"
                    />
                </div>

                <div class="container-block">
                    <h3>Тип налога оператора</h3>
                    <BaseSelect
                        :options="taxSupplierList"
                        :value="taxSupplierSelected"
                        :style="{width: '250px'}"
                        :position="'left'"
                        :canSearch="true"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectSupplierTax"
                    />
                </div>
            </div>

            <div class="container-flex">
                <div class="container-flex setting-checkbox">
                    <BaseCheckboxV3
                        :class="['cell cell-checkbox']"
                        :size="24"
                        :checked="!!isTaxSelected"
                        @change="onChangeCheckboxValue($event, 'isTaxSelected')"
                    />
                    <h3>В файле цена с НДС</h3>
                </div>

                <div class="container-flex setting-checkbox">
                    <BaseCheckboxV3
                        :class="['cell cell-checkbox']"
                        :size="24"
                        :checked="!!isDigitalSelected"
                        @change="onChangeCheckboxValue($event, 'isDigitalSelected')"
                    />
                    <h3>Файл с диджитал конструкциями</h3>
                </div>

                <div class="container-flex setting-checkbox">
                    <BaseCheckboxV3
                        :class="['cell cell-checkbox']"
                        :size="24"
                        :checked="!!isAlwaysActiveSelected"
                        @change="onChangeCheckboxValue($event, 'isAlwaysActiveSelected')"
                    />
                    <h3>Безлимитное действие цены</h3>
                </div>

                <div class="container-flex setting-checkbox">
                    <BaseCheckboxV3
                        :class="['cell cell-checkbox']"
                        :size="24"
                        :checked="!!isSalesHouseSelected"
                        @change="onChangeCheckboxValue($event, 'isSalesHouseSelected')"
                    />
                    <h3>Sales House</h3>
                </div>

                <div class="container-flex setting-checkbox">
                    <BaseCheckboxV3
                        :class="['cell cell-checkbox']"
                        :size="24"
                        :checked="!!isStatusNextRow"
                        @change="onChangeCheckboxValue($event, 'isStatusNextRow')"
                    />
                    <h3>Статус в следующей строке</h3>
                </div>

            </div>

        </div>

        <div v-if="lastFileId" class="cloud-import-edit-setting__container flex-flex">

            <div class="container-flex">
                <BaseButton
                    view="secondary"
                    form="square"
                    @click="onResetTemplate"
                >
                    Сбросить
                </BaseButton>
            </div>


            <AppTable
                primaryKey="id"
                :columns="previewColumns"
                :rows="previewRowsFiltered"
                class="cloud-import__table"
                style="overflow: inherit"
                @columnDropped="OnExchangeRows"
            >
                <template v-slot:reset="{row}">
                    <BaseButton
                        size="small"
                        view="simple"
                        form="square"
                        @click="onResetTemplate(row.addr)"
                    >
                        X
                    </BaseButton>
                </template>
                <template v-slot:select="{row}">
                    <div style="display: flex">
                        <div>
                            <BaseSelectMultipleV2
                                isFullWidth
                                isOptionsFullWidth
                                canSearch
                                :style="{width: '250px'}"
                                :options="templateDropdownItems"
                                :value="getTemplateDropdownValue(row.addr)"
                                :defaultTitle="'Выберите значение'"
                                :isApplyOnClose="true"
                                :previewColor="getPreviewColor(row.addr)"
                                position="left"
                                class="cloud-import__cell-select"
                                @check="onSelectFilterItem($event, row)"
                            />
                        </div>
                        <div>
                            <BaseDatePickerRange
                                v-if="isStatusSelected(row.addr)"
                                :minDate="getPeriodRange(row.addr, true)[0]"
                                :maxDate="getPeriodRange(row.addr, true)[1]"
                                :isDisabled="false"
                                :isButtonWidget="true"
                                :currentDateRange="getPeriodRange(row.addr)"
                                @changeDatePicker="onSelectDropdownValues($event, 'periodStatusesSelected', row.addr)"
                            />
                        </div>
                    </div>
                </template>

                <template
                    v-for="(column, index) in ['templateHeaderRow', 'fileHeaderRow', 'dataRow']"
                    v-slot:[column]="{row}"
                >
                    <div
                        :key="index"
                        :style="getTemplateCellStyle(row, column)"
                    >
                        <div
                            :class="getDifferenceClass(row, column)"
                        >
                            {{getTemplateHeaderCellValue(row, column)}}
                        </div>
                    </div>
                </template>
            </AppTable>

        </div>

        <div v-if="lastFileId" class="cloud-import-edit-setting__container flex-flex">
            <div class="container-flex setting-checkbox">
                <BaseCheckboxV3
                    :class="['cell cell-checkbox']"
                    :size="24"
                    :checked="!!isEmptyStatusSelected"
                    @change="onChangeCheckboxValue($event, 'isEmptyStatusSelected')"
                />
                <h3>Свободно если не выбран цвет или текст статуса</h3>
            </div>
        </div>

        <div class="cloud-import-edit-setting__container flex-flex" v-if="statusValues.length && lastFileId">
            <h2>Текстовые значения статусов в файле</h2>
            <div class="container-flex">
                <div
                    v-for="(priceStatus, index) in priceStatuses"
                    :key="index"
                    class="container-block"
                >
                    <h3>{{ priceStatus.value }}</h3>
                    <BaseSelectMultipleV2
                        canSearch
                        :options="statusValues"
                        :value="getTextValueInFile(priceStatus)"
                        :style="{width: '250px'}"
                        :position="'left'"
                        :isResetButton="true"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectDropdownValues($event, 'statusValuesSelected', priceStatus.id)"
                    />
                </div>
            </div>
        </div>

        <div class="cloud-import-edit-setting__container flex-flex" v-if="statusColors.length && lastFileId">
            <h2>Значения цветов статусов в файле</h2>
            <div class="container-flex">
                <div
                    v-for="(statusColor, index) in statusColors"
                    :key="index"
                    class="container-flex"
                >
                    <div
                        :style="{background: `#${statusColor}`}"
                        class="color-box"
                    ></div>
                    <BaseSelectMultipleV2
                        canSearch
                        :options="priceStatuses"
                        :value="getColorValueInFile(statusColor)"
                        :style="{width: '200px'}"
                        :position="'left'"
                        :isResetButton="true"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectDropdownValues($event, 'statusColorsSelected', statusColor)"
                    />
                </div>
            </div>
        </div>

        <div class="cloud-import-edit-setting__container flex-flex" v-if="lastFileId && taxSupplierValues.length">
            <h2>Текстовые значения налога в файле</h2>
            <div class="container-flex">
                <div
                    v-for="(taxType, index) in taxSupplierList"
                    :key="index"
                    class="container-block"
                >
                    <h3>{{ taxType.value }}</h3>
                    <BaseSelectMultipleV2
                        canSearch
                        :options="taxSupplierValues"
                        :value="getTaxSupplierTextValueInFile(taxType)"
                        :style="{width: '250px'}"
                        :position="'left'"
                        :isResetButton="true"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectTaxSupplierValues($event, taxType.id)"
                    />
                </div>
            </div>
        </div>

        <div class="cloud-import-edit-setting__container flex-flex" v-if="sideTurnValues.length && lastFileId">
            <h2>Текстовые значения положения стороны в файле</h2>
            <div class="container-flex">
                <div
                    v-for="(sideTurn, index) in sideTurns"
                    :key="index"
                    class="container-block"
                >
                    <h3>{{ sideTurn.value }}</h3>
                    <BaseSelectMultipleV2
                        canSearch
                        :options="sideTurnValues"
                        :value="getSideTurnValueInFile(sideTurn)"
                        :style="{width: '250px'}"
                        :position="'left'"
                        :isResetButton="true"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите значение"
                        class="cloud-import-edit-setting__select"
                        @check="onSelectDropdownValues($event, 'sideTurnValuesSelected', sideTurn.id)"
                    />
                </div>
            </div>
        </div>

        <div class="cloud-import-edit-setting__container container-block" v-if="lastFileId">

            <div class="container-block">
                <h2>Статус шаблона</h2>
                <BaseCheckboxV3
                    :class="['cell cell-checkbox']"
                    :size="24"
                    :checked="!!isItOn"
                    @change="onChangeCheckboxValue($event, 'isItOn')"
                />
            </div>
        </div>

        <div class="cloud-import-edit-setting__container container-block" v-if="lastFileId">
            <div class="container-flex">
                <BaseButton
                    view="secondary"
                    form="square"
                    :disabled="!isValidated"
                    @click="onSave"
                >
                    Сохранить
                </BaseButton>
                <BaseButton
                    view="secondary"
                    form="square"
                    style="margin-left: 20px;"
                    :disabled="isValidating"
                    @click="onValidate"
                >
                    <BasePreloader
                        v-if="isValidating"
                        :size="15"
                    />
                    <p v-else>
                        Проверить
                    </p>
                </BaseButton>


            </div>

            <div class="container-flex" style="overflow-x: auto;">
                <CloudImportLog
                    v-if="validatedLogs.length"
                    :rows="validatedLogs"
                    :mode="reportTabMode"
                    @onChangeMode="onChangeReportTab"
                />
            </div>
        </div>

        <CopyTemplateModal
            v-if="isCopyTemplateModal"
            @onCloseCopyTemplateModal="onCloseCopyTemplateModal"
            @onCopyTemplateModal="onCopyTemplateModal"
        />
    </div>
</template>

<script>
import AppTable from '@/components/Table2/Table.vue';
import ServiceCloudImport from "@/services/ServicesCloudImport/ServiceCloudImport";
import BaseButton from "@/components/Base/BaseButton.vue";
import BaseCheckboxV3 from "@/components/Base/BaseCheckboxV3.vue";
import BaseInput from "@/components/Base/BaseInput.vue";
import BaseSelectMultipleV2 from "@/components/Base/BaseSelectMultipleV2.vue";
import BaseSelect from "@/components/Base/BaseSelect.vue";
import ServiceReconciliationImport from "@/services/ServiceReconciliationV2/ServiceReconciliationImport";
import BaseDatePickerRange from "@/components/Base/BaseDatePickerRange.vue";
import UtilDate from "@/utils/UtilDate";
import CloudImportLog from "@/components/CloudImport/CloudImportLog.vue";
import CopyTemplateModal from "@/components/CloudImport/CopyTemplateModal.vue";
import BasePreloader from "@/components/Base/BasePreloader.vue";

export default {
	name: "PageCloudImportEditSettings",
	components: {
        BaseDatePickerRange,
        BaseSelect,
        BaseButton,
        BaseCheckboxV3,
        BaseInput,
        AppTable,
        BaseSelectMultipleV2,
        CloudImportLog,
        BasePreloader,
        CopyTemplateModal
	},
    /**
     * Входные данные компонента
     */
    props: {
        settingId: {
            type: Number,
            default:0
        },
        sheetIndex: {
            type: Number,
            default: 0
        }
    },
	data() {
		return {
            reportTabMode: 'uploaded',
            uploadedAt: '',
            sheetStatuses: [],
            sheetTemplate: {},
            type: '',
            name: '',
            path: '',
            lastFile: {},
            headerRowNumber: 0,
            dataRowNumber: 0,
            previewColumns: [
                {prop: 'reset', label: '', isShow: true, isSlot: true, columnStyles: {width: '50px'}},
                {prop: 'select', label: 'Выберите значение', isShow: true, isSlot: true, columnStyles: {width: '350px'}, isDraggable: true},
                {prop: 'templateHeaderRow', label: 'Заголовок из шаблона', isShow: true, isSlot: true},
                {prop: 'fileHeaderRow', label: 'Заголовок из Excel', isShow: true, isSlot: true},
                {prop: 'dataRow', label: 'Пример данных из Excel', isShow: true, isSlot: true},
            ],
            previewRowsAll: [],
            templateDropdownItems: [],
            templateDropdownSelectedFields: {},
            templateHeader: {},

            supplierList: [],
            supplierSelected: {},
            supplierPage: null,
            supplierSearch: "",
            supplierPageCount: 0,

            cityList: [],
            citySelected: {},
            cityPage: null,
            citySearch: "",
            cityPageCount: 0,

            yearSelected: null,

            periodList: [
                {id: 'month', value: 'Месяц'},
                {id: 'half_month', value: 'Полмесяца'},
                {id: 'day', value: 'День'},
            ],

            taxSupplierList: [
                {id: 'osno-20', value: 'ОСНО - 20%'},
                {id: 'usn-0', value: 'УСН - 0%'},
                {id: 'usn-5', value: 'УСН - 5%'},
                {id: 'usn-7', value: 'УСН - 7%'},
                {id: 'usn-10', value: 'УСН - 10%'},
                {id: 'usn-20', value: 'УСН - 20%'},
            ],

            periodSelected: 'month',
            taxSupplierSelected: 'usn-0',

            periodStatusesSelected: {},

            priceStatuses: [],
            statusColors: [],
            statusValues: [],
            statusValuesSelected: {},
            statusColorsSelected: {},
            taxSupplierValuesSelected: {},

            taxSupplierValues: [],

            sideTurnValues: [],
            sideTurns: [],
            sideTurnValuesSelected: {},

            isItOn: 0,
            isTaxSelected: 0,
            isEmptyStatusSelected: 1,
            isDigitalSelected: 0,
            isAlwaysActiveSelected: 0,
            isSalesHouseSelected: 0,
            isStatusNextRow: 0,
            validatedLogs: [],

            isValidating: false,
            isValidated: false,
            isCopyTemplateModal: false
		}
	},
	computed: {
        currentTemplateSettings() {
            return {
                sheet_index: this.sheetIndex,
                is_on: this.isItOn,
                header_row_index: this.headerRowNumber,
                data_start_row_index: this.dataRowNumber,
                header: this.previewRowsFiltered.map(item => item.fileHeaderRow),
                supplier_tax: this.taxSupplierSelected,
                is_tax: this.isTaxSelected,
                is_empty_status: this.isEmptyStatusSelected,
                is_digital: this.isDigitalSelected,
                is_always_active: this.isAlwaysActiveSelected,
                is_sales_house: this.isSalesHouseSelected,
                is_status_next_row: this.isStatusNextRow,
                fields: this.templateDropdownSelectedFields,
                supplier: this.supplierSelected,
                city: this.citySelected,
                year: this.yearSelected,
                period: this.periodSelected,
                status_values: this.statusValuesSelected,
                status_colors: this.statusColorsSelected,
                tax_supplier_values: this.taxSupplierValuesSelected,
                side_turn_values: this.sideTurnValuesSelected,
                period_statuses: this.periodStatusesSelected
            }
        },

        sideTurnAttrs() {
            return Object.keys(this.templateDropdownSelectedFields).reduce((result, key) => {
                const keyValues = this.templateDropdownSelectedFields[key] ?? [];
                keyValues.forEach((value) => {
                    if(value === 'side_turn' && !result.includes(key)){
                        result.push(key);
                    }
                })
                return result;
            }, [])
        },

        statusAttrs() {
            return Object.keys(this.templateDropdownSelectedFields).reduce((result, key) => {
                let keyValues = this.templateDropdownSelectedFields[key] ?? [];
                if(!Array.isArray(keyValues)){
                    keyValues = Object.values(keyValues)
                }
                keyValues.forEach((value) => {
                    if(value.indexOf("status_") !== -1 && !result.includes(key)){
                        result.push(key);
                    }
                })
                return result;
            }, [])
        },

        taxAttrs() {
            return Object.keys(this.templateDropdownSelectedFields).reduce((result, key) => {
                const keyValues = this.templateDropdownSelectedFields[key] ?? [];
                keyValues.forEach((value) => {
                    if(value === 'tax' && !result.includes(key)){
                        result.push(key);
                    }
                })
                return result;
            }, [])
        },

        defaultYear(){
            return String(new Date().getFullYear())
        },

        yearList(){
            const year = Number(this.defaultYear);
            return [
                {id: String(year - 1), value: String(year - 1)},
                {id: String(year), value: String(year)},
                {id: String(year + 1), value: String(year + 1)},
            ];
        },

        supplierSelectedId(){
            return this.supplierSelected.id ?? null
        },

        citySelectedId(){
            return this.citySelected.id ?? null
        },

        getTypeName() {
            switch (this.type) {
                case "FOLDER":
                    return "Папка";
                default:
                    return "Файл";
            }
        },
        templateStatus() {
            return this.sheetTemplate.status ?? 0;
        },

        lastFileId () {
            return this.lastFile?.id ?? 0
        },

        lastFileName () {
            return this.lastFile?.name ?? "Файл не загружен"
        },

        previewRowsFiltered() {
            const fileHeaderRowIndex = this.previewRowsAll.findIndex(item => item.num === this.headerRowNumber);
            const fileDataRowRowIndex = this.previewRowsAll.findIndex(item => item.num === this.dataRowNumber)

            if(fileHeaderRowIndex === -1 || fileDataRowRowIndex === -1) {
                return [];
            }

            let fileHeaderRow = {...this.previewRowsAll[fileHeaderRowIndex]};

            if(this.isStatusNextRow){
                for(let i = fileHeaderRowIndex + 1; i < fileDataRowRowIndex; i++){
                    Object.keys(fileHeaderRow).forEach((addr) => {
                        if(
                            typeof this.previewRowsAll[i][addr] !== 'undefined'
                            && typeof this.previewRowsAll[i][addr].val !== undefined
                            && this.previewRowsAll[i][addr].val !== undefined
                        ){
                            let cell = {...fileHeaderRow[addr]};
                            cell.val += this.previewRowsAll[i][addr].val;
                            fileHeaderRow = {...fileHeaderRow, [addr]: cell};
                        }
                    })
                }
            }

            const fileDataRow = {...this.previewRowsAll[fileDataRowRowIndex]};

            let resultRows = [];
            Object.keys(fileHeaderRow).forEach((addr) => {
                if(addr !== 'num'){
                    resultRows.push({
                        templateHeaderRow: this.templateHeader[addr] ?? {},
                        fileHeaderRow: fileHeaderRow[addr] ?? {},
                        dataRow: fileDataRow[addr] ?? {},
                        addr: addr
                    })
                }
            })
            return resultRows;
        }
	},
    beforeMount() {
        this.yearSelected = this.defaultYear;
        ServiceCloudImport.detail(this.settingId, this.sheetIndex, (result) => {
            this.setTemplateSettings(result);

            if(this.lastFileId > 0){
                ServiceCloudImport.preview(this.lastFileId, this.sheetIndex, 1, (result) => {
                    const {items = []} = result;
                    this.previewRowsAll = items
                }, () => {}, 100);
            }
        })

        ServiceCloudImport.getFields((result) => {
            const {fields = [], statuses = [], sideTurns = []} = result
            this.templateDropdownItems = fields
            this.priceStatuses = statuses
            this.sideTurns = sideTurns
        })
    },
    created() {

	},
	methods: {
        OnExchangeRows(data){
            const {from = null, to = null} = data
            if(
                this.previewRowsAll.length === 0
                || from === null
                || to === null
            ){
                return;
            }

            const rowAddrs = Object.keys(this.previewRowsAll[0]);
            const fromRowAddr = rowAddrs[from + 1] ?? null;
            const toRowAddr = rowAddrs[to + 1] ?? null;

            if(fromRowAddr === null || toRowAddr === null){
                return;
            }

            const fromSelectedFields = [...(this.templateDropdownSelectedFields[fromRowAddr] ?? [])]
            const toSelectedFields = [...(this.templateDropdownSelectedFields[toRowAddr] ?? [])]

            this.templateDropdownSelectedFields[fromRowAddr] = toSelectedFields;
            this.templateDropdownSelectedFields[toRowAddr] = fromSelectedFields;

            const fromSelectedPeriod = [...(this.periodStatusesSelected[fromRowAddr] ?? [])]
            const toSelectedPeriod = [...(this.periodStatusesSelected[toRowAddr] ?? [])]

            if(fromSelectedPeriod.length){
                this.periodStatusesSelected[toRowAddr] = fromSelectedPeriod
            }else{
                this.onResetPeriod(toRowAddr);
            }

            if(toSelectedPeriod.length){
                this.periodStatusesSelected[fromRowAddr] = toSelectedPeriod
            }else{
                this.onResetPeriod(fromRowAddr);
            }

            this.isValidated = false;
        },

        setTemplateSettings(result){
            const {
                sheetStatuses = [],
                sheetTemplate = {},
                type = "FILE",
                name = "",
                path = "",
                lastFile = {},
                uploadedAt = "",
                header = {}
            } = result;

            this.sheetStatuses = sheetStatuses;
            this.sheetTemplate = sheetTemplate
            this.type = type
            this.name = name
            this.path = path
            this.lastFile = lastFile
            this.uploadedAt = uploadedAt

            const {
                is_on: isItOn = 0,
                header_row_index: headerRowNumber = null,
                data_start_row_index: dataRowNumber = null,
                is_supplier_tax: isSupplierTaxSelected = null,
                supplier_tax: taxSupplierSelected = 'usn-0',
                is_tax: isTaxSelected = 0,
                is_empty_status: isEmptyStatusSelected = 1,
                is_digital: isDigitalSelected = 0,
                is_always_active: isAlwaysActiveSelected = 0,
                is_sales_house: isSalesHouseSelected = 0,
                is_status_next_row: isStatusNextRow = 0,
                fields: templateDropdownSelectedFields = {},
                supplier: supplierSelected = {},
                city: citySelected = {},
                year: yearSelected = this.defaultYear,
                period: periodSelected = 'month',
                tax_supplier_values: taxSupplierValuesSelected = {},
                status_values: statusValuesSelected = {},
                status_colors: statusColorsSelected = {},
                side_turn_values: sideTurnValuesSelected = {},
                period_statuses: periodStatusesSelected = {}
            } = sheetTemplate;

            this.templateHeader = header

            const {data: lastFileData = {}} = lastFile ?? {}
            const {headerRow = 1} = lastFileData

            this.headerRowNumber = headerRowNumber === null ? headerRow : headerRowNumber;
            this.dataRowNumber = dataRowNumber === null ? headerRow + 1 : dataRowNumber;

            switch (true){
                case isSupplierTaxSelected === 0:
                    this.taxSupplierSelected = 'usn-0';
                    break;

                case isSupplierTaxSelected === 1:
                    this.taxSupplierSelected = 'osno-20';
                    break;

                default:
                    this.taxSupplierValuesSelected = taxSupplierSelected;
            }

            this.isItOn = isItOn
            this.taxSupplierSelected = taxSupplierSelected
            this.isTaxSelected = isTaxSelected
            this.isEmptyStatusSelected = isEmptyStatusSelected
            this.isDigitalSelected = isDigitalSelected
            this.isAlwaysActiveSelected = isAlwaysActiveSelected
            this.isSalesHouseSelected = isSalesHouseSelected
            this.isStatusNextRow = isStatusNextRow
            this.templateDropdownSelectedFields = templateDropdownSelectedFields
            this.supplierSelected = supplierSelected
            this.citySelected = citySelected
            this.yearSelected = yearSelected
            this.periodSelected = periodSelected
            this.statusValuesSelected = statusValuesSelected
            this.statusColorsSelected = statusColorsSelected
            this.taxSupplierValuesSelected = taxSupplierValuesSelected ?? {}
            this.sideTurnValuesSelected = sideTurnValuesSelected
            this.periodStatusesSelected = periodStatusesSelected

            this.checkSubData();
            this.checkSupplier()
            this.checkCity();
        },

        getSheetButtonView(status, sheetIndex) {
            switch (true) {
                case sheetIndex === this.sheetIndex:
                    return 'third';
                case status === 1:
                    return 'secondary';
                default:
                    return 'primary';
            }
        },

        onEditSettings(sheetIndex) {
            window.open('/cloud-import/edit-settings?settingId=' + this.settingId + '&sheetIndex=' + sheetIndex, '_self');
        },

        onPreview(){
            window.open('/cloud-import/preview?fileId=' + this.lastFile.id + '&sheetIndex=' + this.sheetIndex, '_blank');
        },

        onCloseCopyTemplateModal() {
            this.isCopyTemplateModal = false;
        },

        onCopyTemplateModal(targetSettingId) {
            ServiceCloudImport.copySetting(targetSettingId, this.settingId, this.sheetIndex, this.copyTemplateModalAfter)
        },

        copyTemplateModalAfter(template) {
            this.setTemplateSettings(template);

            this.isCopyTemplateModal = false;

            this.$notify.dNotify({
                type: 'success',
                title: 'Шаблон скопирован',
                description: '',
                duration: Infinity,
                position: 'bottom-right'
            });
        },

        onCopy() {
            this.isCopyTemplateModal = true;
        },

        onLogs() {
            window.open('/cloud-import/setting-logs?settingId=' + this.settingId, '_blank');
        },

        onClearDate() {
            ServiceCloudImport.clearSetting(this.settingId, this.sheetIndex,() => {
                this.uploadedAt = "";
            })
        },

        onValidate() {
            this.validatedLogs = [];
            this.isValidating = true;
            ServiceCloudImport.validateSetting(
                this.settingId,
                this.currentTemplateSettings,
                (rows, isValidated) => {
                    this.validatedLogs = [...this.validatedLogs, ...rows];
                    this.isValidating = !isValidated;
                    this.isValidated = isValidated;
                },
                () => {
                    this.isValidating = false;
                    this.$notify.dNotify({
                        type: 'success',
                        title: 'Системная ошибка',
                        description: 'Валидация не прошла. Обратитесь к менеджеру',
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }
            )
        },

        onInputHeaderRowNumber(value) {
            this.isValidated = false;
            this.headerRowNumber = Number(value)
            if(this.dataRowNumber <= this.headerRowNumber){
                this.dataRowNumber = this.headerRowNumber + 1
            }
        },

        onInputDataRowNumber(value) {
            this.isValidated = false;
            this.dataRowNumber = Number(value);
            if(this.dataRowNumber <= this.headerRowNumber && this.dataRowNumber > 1){
                this.headerRowNumber = this.dataRowNumber - 1
            }
        },

        onSelectFilterItem(selectValues, row){
            this.templateDropdownSelectedFields = {...this.templateDropdownSelectedFields, [row.addr]: selectValues}

            this.checkSubData()

            this.isValidated = false;
        },

        getTemplateDropdownValue(addr){
            return this.templateDropdownSelectedFields[addr] ?? [];
        },

        getPreviewColor(addr) {
            const isSelected = (this.templateDropdownSelectedFields[addr] ?? []).length > 0
            return isSelected ? '#dedede' : '';
        },

        checkSubData() {
            if(this.statusAttrs.length){
                this.toggleStatusColorsAndValues();
            } else {
                this.statusColors = []
                this.statusValues = []
                this.statusColorsSelected = {};
                this.statusValuesSelected = {};
            }

            if(this.taxAttrs.length){
                this.toggleTaxValues();
            }else {
                this.taxSupplierValues = [];
                this.taxSupplierValuesSelected = {};
            }

            if(this.sideTurnAttrs.length){
                this.toggleSideTurnValues();
            }else{
                this.sideTurnValues = [];
                this.sideTurnValuesSelected = {};
            }
        },

        toggleSideTurnValues() {
            ServiceCloudImport.previewColumnGroups(this.lastFileId, this.sheetIndex, this.sideTurnAttrs, (result) => {
                const {values: sideTurnValues = []} = result

                this.sideTurnValues = sideTurnValues
            });
        },

        toggleStatusColorsAndValues() {
            ServiceCloudImport.previewColumnGroups(this.lastFileId, this.sheetIndex, this.statusAttrs, (result) => {
                const {colors: statusColors = [], values: statusValues = []} = result

                this.statusColors = statusColors
                this.statusValues = statusValues
            });
        },

        toggleTaxValues() {
            ServiceCloudImport.previewColumnGroups(this.lastFileId, this.sheetIndex, this.taxAttrs, (result) => {
                const {values: taxSupplierValues = []} = result

                this.taxSupplierValues = taxSupplierValues
            });
        },

        onSelectSupplier(value) {
            this.isValidated = false;
            this.supplierSelected = value
        },

        onLazyloadSupplier() {
            if(this.supplierPage < (this.supplierPageCount - 1)) {
                this.supplierPage++;
            }
        },

        onSearchSupplier(searchValue = '') {
            this.supplierSearch = searchValue
            this.supplierPage = null
        },

        onOpenSupplierList() {
            if(this.supplierPage === null){
                this.supplierPage = 1;
            }
        },

        async getSupplierListPage() {
            try {
                const {items = [], totalCount = 0} = await ServiceReconciliationImport.getSetting(
                    "suppliers",
                    this.supplierPage,
                    this.supplierSearch
                );

                if(this.supplierPage === 1){
                    this.supplierList = [];
                }

                this.supplierList = [...this.supplierList, ...items];
                this.supplierPageCount = Math.ceil(totalCount / 20);
            } catch (error) {
                console.log(error);
            }
        },

        onSelectYear(value) {
            this.isValidated = false;
            this.yearSelected = value.id
        },

        onSelectPeriod(value) {
            this.isValidated = false;
            this.periodSelected = value.id
        },

        onSelectSupplierTax(value) {
            this.isValidated = false;
            this.taxSupplierSelected = value.id
        },

        onSelectCity(value) {
            this.isValidated = false;
            this.citySelected = value
        },

        onLazyloadCity() {
            if(this.cityPage < (this.cityPageCount - 1)) {
                this.cityPage++;
            }
        },

        onSearchCity(searchValue = '') {
            this.citySearch = searchValue
            this.cityPage = null
        },

        onOpenCityList() {
            if(this.cityPage === null){
                this.cityPage = 1;
            }
        },

        async getCityListPage() {
            try {
                const {items = [], totalCount = 0} = await ServiceReconciliationImport.getSetting(
                    "cities",
                    this.cityPage,
                    this.citySearch
                );
                if(this.cityPage === 1){
                    this.cityList = [];
                }
                this.cityList = [...this.cityList, ...items];
                this.cityPageCount = Math.ceil(totalCount / 20);
            } catch (error) {
                console.log(error);
            }
        },

        onChangeCheckboxValue(value, attribute) {
            this.isValidated = false;
            this.$set(this, attribute, value ? 1 : 0)
        },

        onSelectTaxSupplierValues(values, attribute) {
            this.taxSupplierValuesSelected = {...this.taxSupplierValuesSelected, [attribute]: values}
        },

        onSelectDropdownValues(values, attribute, key = null) {
            if(key === null){
                this.$set(this, attribute, values)
            }else{
                this.$set(this[attribute], key, values)
            }

            this.isValidated = false;
        },

        getTextValueInFile(priceStatus) {
            return this.statusValuesSelected[priceStatus.id] ?? []
        },

        getTaxSupplierTextValueInFile(taxType) {
            return this.taxSupplierValuesSelected[taxType.id] ?? []
        },

        getColorValueInFile(statusColor) {
            return this.statusColorsSelected[statusColor] ?? []
        },

        getSideTurnValueInFile(sideTurn) {
            return this.sideTurnValuesSelected[sideTurn.id] ?? [];
        },

        getTemplateCellStyle(row, column) {
            return {
                background: column === 'dataRow' ? row[column].color : 'transparent',
                width: '100%',
                height: '100%'
            }
        },

        getTemplateHeaderCellValue(row, addr) {
            if(row[addr] !== undefined){
                return row[addr].val
            }
            return "";
        },

        getDifferenceClass(row, addr) {
            if(addr === 'dataRow' || !Object(this.templateHeader).length){
                return "";
            }

            if(row?.templateHeaderRow?.val !== row?.fileHeaderRow?.val){
                return "cell-bold";
            }

            return "";
        },

        onSave(){
            ServiceCloudImport.editSetting(this.settingId, this.currentTemplateSettings, (result) => {
                this.setTemplateSettings(result);

                this.$notify.dNotify({
                    type: 'success',
                    title: 'Шаблон сохранен',
                    description: '',
                    duration: Infinity,
                    position: 'bottom-right'
                });
            })
        },

        checkCity(){
            const {id: cityId = null} = this.citySelected
            if(cityId === null){
                return;
            }
            const cityListIndex = this.cityList.findIndex((city) => {
                return city.id === cityId
            })
            if(cityListIndex === -1){
                this.cityList.push(this.citySelected)
            }
        },

        checkSupplier() {
            const {id: supplierId = null} = this.supplierSelected
            if(supplierId === null){
                return;
            }
            const supplierListIndex = this.supplierList.findIndex((supplier) => {
                return supplier.id === supplierId
            })
            if(supplierListIndex === -1){
                this.supplierList.push(this.supplierSelected)
            }
        },

        getPeriodRange(addr, isOnlyDefault = false) {
            const selectedStatusPeriod = this.periodStatusesSelected[addr] ?? null;
            if(!isOnlyDefault && Array.isArray(selectedStatusPeriod) && selectedStatusPeriod.length === 2){
                return selectedStatusPeriod;
            }

            const fields = this.templateDropdownSelectedFields[addr] ?? [];
            const {statusMonth} = this.getStatusInfo(fields);
            const firstDay = UtilDate.toString(this.yearSelected, statusMonth);
            const lastDay = UtilDate.toStringLastDayOfMonth(this.yearSelected, statusMonth);
            return [firstDay, lastDay];
        },

        isStatusSelected(addr) {
            if(this.periodSelected === 'month'){
                return false;
            }
            const fields = this.templateDropdownSelectedFields[addr] ?? [];
            const {countStatuses, statusMonth} = this.getStatusInfo(fields);
            return countStatuses === 1 && statusMonth >= 1;
        },

        getStatusInfo(fields) {
            let countStatuses = 0;
            let statusMonth = null;
            fields.forEach((field) => {
                const isStatus = field.indexOf("status_") !== -1;
                let month;
                if(isStatus){
                    [,month] = field.split("_");
                }
                if(isStatus && Number(month) > 0 && Number(month) < 13 && statusMonth !== 0){
                    countStatuses++;
                    statusMonth = Number(month);
                }
                if(isStatus && (Number(month) <= 0 && Number(month) >= 13) || isNaN(Number(month))){
                    statusMonth = 0;
                }
            })
            return {countStatuses, statusMonth};
        },

        onResetTemplate(addr = null) {
            this.isValidated = false;

            if(addr === null){
                this.templateDropdownSelectedFields = {}
                this.periodStatusesSelected = {}
                return;
            }

            if(Array.isArray(this.templateDropdownSelectedFields[addr] ?? null)){
                this.templateDropdownSelectedFields = {...this.templateDropdownSelectedFields, [addr]: []}
            }

            this.onResetPeriod(addr)

            this.checkSubData();
        },

        onResetPeriod(addr) {
            if(Array.isArray(this.periodStatusesSelected[addr] ?? null)){
                let periodStatusesSelected = {...this.periodStatusesSelected}
                delete periodStatusesSelected[addr]
                this.periodStatusesSelected = {...periodStatusesSelected}
            }
        },

        onChangeReportTab(mode){
            this.reportTabMode = mode;
        }
	},
	watch: {
        supplierPage(){
            if(this.supplierPage === null){
                this.supplierPage = 1;
            }

            this.getSupplierListPage();
        },

        cityPage(){
            if(this.cityPage === null){
                this.cityPage = 1;
            }

            this.getCityListPage();
        },

        cityList(){
            this.checkCity()
        },

        supplierList() {
            this.checkSupplier()
        }
	}
}
</script>

<style lang="scss" scoped>
.cell-bold {
    font-weight: bold;
    text-decoration: underline;
    color: #d90000;
}

.cloud-import-edit-setting {
    height: 100%;
    overflow-y: auto;

    &__select {
        padding: 5px;
    }

    &__table {
        width: 100%;
        height: auto;
        max-height: 100vh;
    }

    &__container {
        display: block;
        margin: 20px;
        padding: 20px;
        background: #f4f5f5;
        border-radius: 10px;
        width: auto;

        .container-block {
            display: block;
            padding: 10px;
        }

        .setting-checkbox{
            align-items: center;
            //width: 150px;
        }

        .container-flex {
            display: flex;
            padding: 10px;

            .base-checkbox{
                margin: 5px;
            }
        }

        h2{
            padding: 5px;
        }

        .color-box {
            height: 50px;
            width: 50px;
            border: 1px solid #ffffff;
        }
    }

    &__sheet-container .btn{
        margin: 5px;
        padding: 10px;
    }

    &__detail {
        padding: 16px;
        max-width: 400px;
    }

    &__detail h2 {
        margin-top: 0;
    }

    &__detail p {
        margin: 4px 0;
    }
}

.number-button {
    margin: 5px;
}
</style>
